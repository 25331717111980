import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BBadge = _resolveComponent("BBadge")!

  return (_openBlock(), _createBlock(_component_BBadge, {
    variant: _ctx.variant,
    class: "app-badge"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], undefined, true),
    _: 3
  }, 8, ["variant"]))
}