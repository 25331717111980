<script setup lang="ts">
import ValidationObserver from "@/components/organisms/validation/ValidationObserver.vue";
import { ref } from "vue";

const props = defineProps({
  scrollBasedSelector: {
    type: String,
    default: "body"
  },
  scrollOffset: {
    type: Number,
    default: 0
  }
});

const validationObserver = ref<typeof ValidationObserver | null>(null);

async function handleSubmit(validAction: Function, invalidAction?: Function) {
  if (!validationObserver.value) return;
  const { valid } = await validationObserver.value.validate();
  if (!valid) {
    if (invalidAction) await invalidAction();
    scrollToInvalidElement();
    return;
  }
  await validAction();
}

function scrollToInvalidElement() {
  const firstErrorElement = [
    ...document.querySelectorAll(".invalid-feedback")
  ].find(el => el.textContent?.trim() !== "");
  if (!firstErrorElement) return;
  // スクロール対象の要素を探す
  const targetElement = findTargetElement(firstErrorElement.parentElement);
  if (!targetElement) return;
  const elementPosition = targetElement.getBoundingClientRect().top;
  const basedElement = document.querySelector(props.scrollBasedSelector);
  // スクロールの基準位置を取得
  const offsetTop = basedElement?.getBoundingClientRect().top || 0;
  window.scrollTo({
    top: elementPosition - (offsetTop + props.scrollOffset),
    behavior: "smooth"
  });
}

function findTargetElement(parent: HTMLElement | null): HTMLElement | null {
  if (!parent) return null;
  const action = parent.getAttribute("data-action");
  if (action && action === "scroll") return parent;
  return findTargetElement(parent.parentElement);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function resetForm(values: any) {
  if (!validationObserver.value) return;
  validationObserver.value.reset(values);
}

defineExpose({ resetForm });
</script>

<template>
  <ValidationObserver v-bind="$attrs" ref="validationObserver">
    <slot :handleSubmit="handleSubmit" />
  </ValidationObserver>
</template>
