<template>
  <BBadge :variant="variant" class="app-badge">
    <slot />
  </BBadge>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class AppLabel extends Vue {
  @Prop({
    required: false,
    default: "primary",
    type: String
  })
  variant!: string;
}
</script>

<style scoped>
.app-badge {
  height: 20px;
  font-weight: normal;
  line-height: 14px;
}
</style>
