<template>
  <Transition appear name="fade">
    <div v-if="isVisible" class="c-loading" :style="{ zIndex }">
      <div class="spinner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
          class="svg"
          style="transform: rotate(0deg);"
        >
          <circle
            fill="transparent"
            cx="45.714285714285715"
            cy="45.714285714285715"
            r="20"
            stroke-width="5.714285714285714"
            stroke-dasharray="125.664"
            stroke-dashoffset="125.66370614359172px"
            class="circle"
          ></circle>
        </svg>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

const defaultZIndex = 1010;
@Component
export default class Loading extends Vue {
  isVisible = false;
  zIndex = defaultZIndex;

  show(zIndex = defaultZIndex) {
    this.zIndex = zIndex;
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }
}
</script>

<style lang="scss" scoped>
.c-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#fffefe, 0.8);
}

.spinner {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 32px;
  color: #00b9f2;
  vertical-align: middle;
}

.svg {
  animation-name: spinnerSvg;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.circle {
  stroke: currentcolor;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation-name: spinnerCircle;
  animation-duration: 1.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes spinnerSvg {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinnerCircle {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
