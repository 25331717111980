import {
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: "maintenance"
})
class MaintenanceModule extends VuexModule {
  isInMaintenance = false;

  @Mutation
  SET_IS_IN_MAINTENANCE(isInMaintenance: boolean) {
    this.isInMaintenance = isInMaintenance;
  }
}

export const maintenanceStore = getModule(MaintenanceModule);
