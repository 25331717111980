<template>
  <BFormInput
    :value="value"
    :type="type"
    :state="state"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    @input="onInput"
    @blur="onBlur"
    class="app-textfield"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { ValidationStateType } from "@/types/Validation";
import { getFormState } from "@/utils/validation";

@Component
export default class AppTextField extends Vue {
  get state() {
    return getFormState(this.validationState);
  }

  @Prop({
    type: String || Number,
    required: true,
    default: ""
  })
  value!: string | number;

  @Prop({
    type: String,
    default: ""
  })
  label!: string;

  @Prop({
    type: String,
    default: ""
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "text"
  })
  type!: string;

  @Prop({
    type: Object,
    default: () => ({})
  })
  validationState!: ValidationStateType;

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  onInput(v: string | number) {
    this.$emit("input", v);
  }

  onBlur(v: string | number) {
    this.$emit("blur", v);
  }
}
</script>

<style lang="scss" scoped>
.app-textfield {
  background: #fff;
  border: 1px solid #ccc;

  &:disabled,
  &[readonly] {
    background-color: $gray-200;
  }
}
</style>
