<template>
  <BFormInvalidFeedback v-if="validationState" :state="state">
    {{ validationState.errors[0] }}
  </BFormInvalidFeedback>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class FormInvalidFeedback extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  state!: boolean;

  @Prop({
    type: Object,
    default: {}
  })
  validationState!: object[];
}
</script>
