import { ComponentPublicInstance } from "vue";
import { NavigationFailure, RouteLocationRaw } from "vue-router";

/**
 * コンソールアプリとして動作しているかを URL から判定する
 */
export const isConsoleByUrl = (url: string) => {
  const consoleUrlOrigin = process.env.VUE_APP_CONSOLE_URL_ORIGIN;
  return url === consoleUrlOrigin;
};

/*
  sentry で navigation guard 関係のエラーが頻繁に通知される。
  実際の動作的には何も問題ないので、該当のエラーを握りつぶす実装をしている。
*/
export const routerPush = async (
  self: ComponentPublicInstance,
  location: RouteLocationRaw
): Promise<void | NavigationFailure | undefined> => {
  return await self.$router.push(location).catch(() => {
    // Do Nothing
  });
};

/*
  sentry で navigation guard 関係のエラーが頻繁に通知される。
  実際の動作的には何も問題ないので、該当のエラーを握りつぶす実装をしている。
*/
export const routerReplace = async (
  self: ComponentPublicInstance,
  location: RouteLocationRaw
): Promise<void | NavigationFailure | undefined> => {
  return await self.$router.replace(location).catch(() => {
    // Do Nothing
  });
};
