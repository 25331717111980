import { NavigationGuard } from "vue-router";
import { authStore } from "@/store/auth";
import { isConsoleByUrl } from "@/utils/url";

export const checkPasswordForceChange: NavigationGuard = (to, _from, next) => {
  if (to.meta?.allowAnonymous) {
    next();
    return;
  }

  // 他のレイヤーで location を操作したくないので、冗長ではあるが各 guards にて定義
  const url = `${location.protocol}//${location.host}`;
  const isConsole = isConsoleByUrl(url);

  // ログイン後、初回パスワードリセットが完了していない場合は
  // `PasswordForceChange`に遷移
  const passwordChangeRouteName = isConsole
    ? "ConsolePasswordForceChange"
    : "PasswordForceChange";
  if (
    !authStore.user?.hasChangedInitialPassword &&
    to.name !== passwordChangeRouteName
  ) {
    next({
      name: passwordChangeRouteName
    });
    return;
  }

  next();
};
