import {
  NavigationGuardNext,
  RouteLocation,
  RouteLocationRaw
} from "vue-router";
import router from "@/router";
import { corporationsStore } from "@/store/corporations";
import { routerPush } from "@/utils/url";

let isBrowserNavigation = false;

window.addEventListener("popstate", () => {
  isBrowserNavigation = true;
});

export function officeIdHandler(
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext
) {
  if (
    isBrowserNavigation || // ブラウザバック/フォワードの場合はオフィスID設定対象外
    to.query.officeId ||
    !corporationsStore.selectedOfficeId
  ) {
    next();
  } else {
    const query = {
      ...to.query,
      officeId: String(corporationsStore.selectedOfficeId)
    };
    const location: RouteLocationRaw = {
      ...to,
      query,
      name: to.name! // name属性は必須なので指定が必要
    };
    // @ts-ignore
    const vm = router.app;
    routerPush(vm, location);
  }
  isBrowserNavigation = false;
}
