import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-mild mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLabel = _resolveComponent("AppLabel")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_FormInvalidFeedback = _resolveComponent("FormInvalidFeedback")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createVNode(_component_AppLabel, { variant: "red" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.appLabelText), 1)
      ], undefined, true),
      _: 1
    }),
    _createTextVNode(),
    _createVNode(_component_AppTextField, {
      value: _ctx.value,
      type: _ctx.type,
      "validation-state": _ctx.validationState,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      onInput: _ctx.onInput,
      onBlur: _ctx.onBlur
    }, null, 8, ["value", "type", "validation-state", "placeholder", "disabled", "readonly", "onInput", "onBlur"]),
    _createTextVNode(),
    _createVNode(_component_FormInvalidFeedback, {
      "validation-state": _ctx.validationState,
      state: false
    }, null, 8, ["validation-state"])
  ]))
}