import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  id: "app-environment"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterView),
    _createTextVNode(),
    (!!_ctx.environment && _ctx.environment !== 'prod')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.environment), 1))
      : _createCommentVNode("", true)
  ]))
}