<template>
  <div id="app">
    <RouterView />
    <div v-if="!!environment && environment !== 'prod'" id="app-environment">
      {{ environment }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from "vue-facing-decorator";
import { maintenanceStore } from "@/store/maintenance";
import { maintenanceService } from "@/app/services/MaintenanceService";
import { authStore } from "./store/auth";
import { accountService } from "./console/app/services/AccountService";
import { showErrorModal } from "./utils/showErrorModal";

const intervalTime = 300000;

@Component
export default class App extends Vue {
  private maintenanceIntervalId: ReturnType<typeof setTimeout> | null = null;

  get isLoggedIn() {
    return authStore.existToken;
  }

  async created() {
    // メンテナンス情報を取得
    this.fetchIsInMaintenance();
    this.maintenanceIntervalId = setInterval(
      this.fetchIsInMaintenance,
      intervalTime
    );
    if (this.isLoggedIn) {
      try {
        const user = await accountService.fetchUserData();
        authStore.setUserInfo(user);
      } catch (e) {
        showErrorModal(this, e as Error);
        // ユーザー情報の取得に失敗した場合はログアウト
        authStore.logout();
      }
    }
  }
  unmounted() {
    clearInterval(this.maintenanceIntervalId as ReturnType<typeof setTimeout>);
  }
  get isInMaintenance() {
    return maintenanceStore.isInMaintenance;
  }
  get environment() {
    return process.env.VUE_APP_ENV;
  }
  async fetchIsInMaintenance() {
    const isInMaintenance = await maintenanceService.fetchIsInMaintenance();
    maintenanceStore.SET_IS_IN_MAINTENANCE(isInMaintenance);
  }
  @Watch("isInMaintenance", { immediate: true })
  async handler(v: boolean) {
    // メンテナンスになった場合
    // メンテナンスから復帰する処理はsrc/views/maintenance/index.vueに記載
    if (v) await this.$router.replace({ name: "Maintenance" });
  }
}
</script>

<style lang="scss">
#app {
  min-width: 1280px;
}

#app-environment {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  margin: 0;
  background: yellow;
}
</style>
