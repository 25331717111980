<template>
  <div>
    <label v-if="label" class="text-mild mr-2">{{ label }}</label>
    <AppLabel variant="red">{{ appLabelText }}</AppLabel>
    <AppTextField
      :value="value"
      :type="type"
      :validation-state="validationState"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      @input="onInput"
      @blur="onBlur"
    />
    <FormInvalidFeedback :validation-state="validationState" :state="false" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppTextField from "@/components/atoms/input/AppTextField.vue";
import FormInvalidFeedback from "@/components/atoms/other/FormInvalidFeedback.vue";
import AppLabel from "@/components/atoms/input/AppLabel.vue";
import { ValidationStateType } from "@/types/Validation";

@Component({
  components: {
    AppTextField,
    FormInvalidFeedback,
    AppLabel
  }
})
export default class TextFieldGroup extends Vue {
  @Prop({
    type: String || Number,
    required: true,
    default: ""
  })
  value!: string | number;

  @Prop({
    type: String,
    default: ""
  })
  label!: string;

  @Prop({
    type: String,
    default: ""
  })
  appLabelText!: string;

  @Prop({
    type: String,
    default: ""
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "text"
  })
  type!: string;

  @Prop({
    type: Object,
    default: {}
  })
  validationState!: ValidationStateType;

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  onInput(v: string) {
    this.$emit("input", v);
  }

  onBlur(v: string) {
    this.$emit("blur", v);
  }
}
</script>
