import { NavigationGuard } from "vue-router";
import { authStore } from "@/store/auth";
import { isConsoleByUrl } from "@/utils/url";

export const checkPermission: NavigationGuard = (to, _from, next) => {
  const {
    allowAnonymous,
    allowCorporationUser,
    allowCorporationRoot,
    allowCorporationAreaManager,
    allowStaff
  } = to.meta;
  if (allowAnonymous) {
    next();
    return;
  }
  // 他のレイヤーで location を操作したくないので、冗長ではあるが各 guards にて定義
  const url = `${location.protocol}//${location.host}`;
  const isConsole = isConsoleByUrl(url);

  const user = authStore.user;
  if (!user) {
    // NOTE: router/index.ts でこのガードの前に checkAuth が定義されているため、認証情報が存在しない場合はそこでエラーになる
    // そのため、原則ここにたどり着くケースは無いはず
    next({ name: isConsole ? "ConsoleRoot" : "Root" });
    return;
  }

  // コンソールドメインでドメイン直下（タスク一覧）にアクセスされた場合、法人のルートパスに移動する
  if (to.name === "Tasks" && isConsole) {
    next({ name: "ConsoleRoot" });
    return;
  }

  // 法人ユーザ
  if (allowCorporationUser && user?.corporation) {
    next();
    return;
  }

  // 法人ルートユーザー
  if (allowCorporationRoot && user?.isRoot) {
    next();
    return;
  }

  // 法人エリア管理者
  if (allowCorporationAreaManager && user?.isAreaManager) {
    next();
    return;
  }

  // コンソールユーザー
  if (allowStaff && user?.isStaff) {
    next();
    return;
  }

  console.error(
    `${to.name as string} に遷移しようとしましたが権限がありません`
  );
  alert("アクセス権限がありません");
};
