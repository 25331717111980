import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Corporation } from "@/types/corporation/Corporation";
import { Office } from "@/types/office/Office";

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: "corporations"
})
class CorporationsModule extends VuexModule {
  corporation: Corporation | null = null;
  selectedOfficeId: number | null = null;
  offices: Office[] = [];

  @Mutation
  private SET_SELECTED_OFFICE_ID(officeId: number | null) {
    this.selectedOfficeId = officeId;
  }

  @Mutation
  private SET_CORPORATION(corporation: Corporation) {
    this.corporation = corporation;
  }

  @Mutation
  private CLEAR_CORPORATION() {
    this.corporation = null;
  }

  @Mutation
  private SET_OFFICES(offices: Office[]) {
    this.offices = offices;
  }

  @Action
  setSelectedOfficeId(officeId: number | null) {
    this.SET_SELECTED_OFFICE_ID(officeId);
  }

  @Action
  setCorporation(corporation: Corporation) {
    this.SET_CORPORATION(corporation);
  }

  @Action
  clearCorporation() {
    this.CLEAR_CORPORATION();
  }

  @Action
  setOffices(offices: Office[]) {
    this.SET_OFFICES(offices);
  }
}

export const corporationsStore = getModule(CorporationsModule);
