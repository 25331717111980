<script setup lang="ts">
// NOTE: このコンポーネントは vee-validate v3 で書かれたコードをカバーするために作成したものです。
// 新規でフォームを用意する場合は、原則 ValidationForm (plugins 内で定義した vee-validate のコンポーネント) を利用してください。
import { Form, SubmissionHandler } from "vee-validate";
import { ref, PropType } from "vue";

defineProps({
  tag: {
    type: String,
    default: "div"
  },
  initialValues: {
    type: Object,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: () => ({} as PropType<Record<string, any>>)
  }
});

const form = ref<typeof Form | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reset = (values?: any) => form.value!.resetForm(values);

const validate = () => form.value!.validate();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setValues = (values?: any) => form.value!.setValues(values);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setErrors = (values?: any) => form.value!.setErrors(values);

const submit = async (func: Function) => {
  const { valid } = await validate();
  if (!valid) {
    return;
  }
  // Form の handleSubmit を使うと引数が追加されるため、ここで引数無しで呼び出す
  return func();
};

defineExpose({
  reset,
  validate,
  setValues,
  setErrors
});
</script>

<template>
  <Form
    v-slot="{ meta, resetForm, setErrors }"
    ref="form"
    :as="tag"
    :initialValues="initialValues"
  >
    <slot
      :handleSubmit="submit"
      :valid="meta.valid"
      :resetForm="resetForm"
      :setErrors="setErrors"
    />
  </Form>
</template>
