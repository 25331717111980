import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BFormInvalidFeedback = _resolveComponent("BFormInvalidFeedback")!

  return (_ctx.validationState)
    ? (_openBlock(), _createBlock(_component_BFormInvalidFeedback, {
        key: 0,
        state: _ctx.state
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.validationState.errors[0]), 1)
        ], undefined, true),
        _: 1
      }, 8, ["state"]))
    : _createCommentVNode("", true)
}