import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Master, Qualification, Service } from "@/types/Master";
import { systemService } from "@/app/services/SystemService";

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: "master"
})
class MasterModule extends VuexModule {
  master!: Master;

  get qualificationMap(): Map<number, Qualification> {
    const map = this.master.qualifications.reduce((acc, obj) => {
      acc.set(obj.id, obj);
      return acc;
    }, new Map());
    return map;
  }

  get qualifications() {
    return this.master.qualifications;
  }

  get banks() {
    return this.master.banks;
  }

  get recommendationPoints() {
    return this.master.recommendationPoints;
  }

  get japaneseHolidaySet(): Set<string> {
    return this.master.japaneseHolidays.reduce((acc, obj) => {
      acc.add(obj.date);
      return acc;
    }, new Set<string>());
  }

  // 事業所用のサービス種類コードの取得
  // NOTE: master への設定時に除外したいが、そうするとワーカー一覧などで onlyWorkerExperience が true のラベルを表示できなくなる
  get servicesForOffice(): Service[] {
    return this.master.services.filter(service => {
      return !service.onlyWorkerExperience;
    });
  }

  get getServiceNameById() {
    return (id: number) =>
      this.master.services.find(service => service.id === id)?.name;
  }

  get getServiceNameForStaffById() {
    return (id: number) =>
      this.master.services.find(service => service.id === id)?.nameForStaff;
  }

  @Mutation
  SET_MASTER(master: Master) {
    this.master = master;
  }

  @Action
  async fetchMaster() {
    const master = await systemService.fetchMaster();
    this.SET_MASTER(master);
  }
}

export const masterStore = getModule(MasterModule);
