import { NavigationGuard } from "vue-router";
import { authStore } from "@/store/auth";
import { authService } from "@/app/services/AuthService";
import { isConsoleByUrl } from "@/utils/url";
import { RouteLocation, RouteLocationRaw } from "vue-router";

const nextLocation = (to: RouteLocation): RouteLocationRaw => {
  // 他のレイヤーで location を操作したくないので、冗長ではあるが各 guards にて定義
  const url = `${location.protocol}//${location.host}`;
  const loginRouteName = isConsoleByUrl(url) ? "ConsoleLogin" : "Login";

  return to.fullPath
    ? {
        name: loginRouteName,
        query: { redirectUrl: to.fullPath }
      }
    : {
        name: loginRouteName
      };
};

export const checkAuth: NavigationGuard = async (to, _from, next) => {
  // 対象ページがチェック
  if (to.meta?.allowAnonymous) {
    next();
    return;
  }

  // トークン持ってるかチェック
  const isExistToken = authStore.existToken;
  if (!isExistToken) {
    // ログイン必要
    next(nextLocation(to));
    return;
  }

  const isTokenExpired = authStore.tokenIsExpired();
  // 切れてたらログインページ
  if (isTokenExpired) {
    await authStore.logout();
    next(nextLocation(to));
    return;
  }

  const isTokenExpiredInNear = authStore.tokenIsNearExpired();
  // もうすぐ有効期限が切れる場合
  if (isTokenExpiredInNear) {
    // リフレッシュ
    const isOk = await authService.refreshToken();
    if (!isOk) {
      next(nextLocation(to));
      return;
    }
  }

  next();
};
