import axios from "axios";
import { authStore } from "@/store/auth";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE
});

axiosInstance.interceptors.request.use(config => {
  const token = authStore.token;
  if (token) {
    config.headers.common["Authorization"] = `JWT ${token}`;
  }

  const username = authStore.user?.username;
  if (username) {
    config.headers.common["X-User-Username"] = username;
  }

  return config;
});

export const axiosClient = axiosInstance;
