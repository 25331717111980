import * as Sentry from "@sentry/vue";
import { authStore } from "@/store/auth";

export const captureException = (error: Error | string) => {
  console.log("captureException", error);
  if (`${process.env.VUE_APP_SENTRY_ENVIRONMENT}`.match(/local/)) {
    console.log("ローカル環境なのでSentryへの送信はスキップします");
    return;
  }
  Sentry.captureException(
    typeof error === "string" ? new Error(error) : error,
    {
      extra: {
        service: process.env.VUE_APP_SERVICE_NAME,
        userid: authStore.user?.id,
        username: authStore.user?.username
      }
    }
  );
};
