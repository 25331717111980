import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Qualification } from "@/types/qualification/Qualification";
import { qualificationsService } from "@/app/services/QualificationsService";

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: "qualification"
})
class QualificationModule extends VuexModule {
  qualifications: Qualification[] = [];
  private isFetched = false;

  get getQualificationNameById() {
    return (id: number) =>
      this.qualifications.find(qualification => qualification.id === id)?.name;
  }

  @Mutation
  private SET_QUALIFICATIONS(qualifications: Qualification[]) {
    this.qualifications = qualifications;
  }

  @Mutation
  private SET_IS_FETCHED(isFetched: boolean) {
    this.isFetched = isFetched;
  }

  @Action
  async fetchQualifications() {
    if (this.qualifications.length || this.isFetched) {
      return;
    }

    this.SET_IS_FETCHED(true);
    const qualifications = await qualificationsService
      .fetchQualificationsList()
      .then(res => res.results);

    this.SET_QUALIFICATIONS(qualifications);
  }
}

export const qualificationStore = getModule(QualificationModule);
