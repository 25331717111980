import { httpClient } from "@/app/client/httpClient";
import { authStore } from "@/store/auth";
import { AxiosError } from "axios";

class AuthService {
  async login(body: { email: string; password: string }) {
    const authInfo = await httpClient.auth.login.$post({
      body
    });

    await authStore.login(authInfo);
  }

  async loginConsole(body: { usernameOrEmail: string; password: string }) {
    const authInfo = await httpClient.admin.auth.login.$post({
      body
    });

    await authStore.login(authInfo);
  }

  /**
   * tokenをリフレッシュします
   * @returns {boolean} トークンリフレッシュが成功したかどうか
   */
  async refreshToken(): Promise<boolean> {
    const token = authStore.token;
    if (!token) {
      await authStore.logout();
      return false;
    }

    return (
      httpClient.auth.token.refresh
        .$post({
          body: {
            token
          }
        })
        .then(({ token }) => {
          authStore.refresh({
            token
          });
          return true;
        })
        // eslint-disable-next-line
        .catch((err: AxiosError) => {
          console.log("cant refresh... login again");

          const tokenLifetime = authStore.tokenLifetime();

          // refresh 失敗したとき, 有効期限切れトークンをもつ場合はトークンクリアする
          if (tokenLifetime === null || tokenLifetime <= 0) {
            authStore.logout();
          }
          return false;
        })
    );
  }

  resetPassword(body: { email: string }) {
    return httpClient.auth.password.reset.$post({
      body
    });
  }

  passwordResetConfirm(body: {
    newPassword1: string;
    newPassword2: string;
    uid: string;
    token: string;
  }) {
    return httpClient.auth.password.reset.confirm.$post({ body });
  }

  changePassword(body: {
    oldPassword: string;
    newPassword1: string;
    newPassword2: string;
  }) {
    return httpClient.auth.password.change.$post({ body }).then(() => {
      const user = authStore.user;
      const hasChangedInitialPassword = user?.hasChangedInitialPassword;
      if (!hasChangedInitialPassword) {
        const updatedUser = Object.assign({}, user);
        updatedUser.hasChangedInitialPassword = true;
        authStore.setUserInfo(updatedUser);
      }
      return true;
    });
  }
}

export const authService = new AuthService();
