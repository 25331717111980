import { ComponentPublicInstance } from "vue";

class LoadingManager implements ILoadingManager {
  constructor(private vm: ComponentPublicInstance, private counter = 0) {}

  show(zIndex: number | undefined) {
    const loading = this.vm!.$refs.loading as ILoadingManager;
    loading.show(zIndex);
    this.counter++;
  }

  hide() {
    this.counter--;

    if (this.counter <= 0) {
      const loading = this.vm!.$refs.loading as ILoadingManager;
      loading.hide();
    }
  }
}

export { LoadingManager };

export interface ILoadingManager {
  show(zIndex?: number | undefined): void;

  hide(): void;
}
