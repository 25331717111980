import { RouteLocation } from "vue-router";
import { captureException } from "@sentry/vue";

export const munchkinVisitWebPage = (to: RouteLocation) => {
  // @ts-ignore
  const Munchkin = window.Munchkin;
  if (!Munchkin) return;
  try {
    Munchkin.munchkinFunction("visitWebPage", {
      url: to.path,
      params: new URLSearchParams(
        to.params as Record<string, string>
      ).toString()
    });
  } catch (e) {
    captureException(e as Error);
  }
};
