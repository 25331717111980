/* eslint-disable */
import { AspidaClient } from 'aspida'
import { Methods as Methods0 } from './accounts/email'
import { Methods as Methods1 } from './accounts/email/_id@number/sent_confirmation'
import { Methods as Methods2 } from './accounts/leave'
import { Methods as Methods3 } from './accounts/me'
import { Methods as Methods4 } from './accounts/me/bank_account'
import { Methods as Methods5 } from './accounts/me/is_staff'
import { Methods as Methods6 } from './accounts/me/is_superuser'
import { Methods as Methods7 } from './accounts/me/qualifications'
import { Methods as Methods8 } from './accounts/me/qualifications/_id@number'
import { Methods as Methods9 } from './accounts/me/request_verification'
import { Methods as Methods10 } from './accounts/rejection_reason'
import { Methods as Methods11 } from './accounts/verification_request_queue'
import { Methods as Methods12 } from './accounts/verification_request_queue/_id@number'
import { Methods as Methods13 } from './accounts/verification_request_queue/_id@number/check_duplicate'
import { Methods as Methods14 } from './admin/adminlog'
import { Methods as Methods15 } from './admin/auth/login'
import { Methods as Methods16 } from './admin/gift_request'
import { Methods as Methods17 } from './admin/gift_request/_id@number'
import { Methods as Methods18 } from './admin/information'
import { Methods as Methods19 } from './admin/information/_id@number'
import { Methods as Methods20 } from './admin/information/_id@number/download_target_list_csv'
import { Methods as Methods21 } from './admin/workers'
import { Methods as Methods22 } from './admin/workers/_id@string/update_memo'
import { Methods as Methods23 } from './admin/workers/bank_account'
import { Methods as Methods24 } from './admin/workers/bank_account/detail/_id@number'
import { Methods as Methods25 } from './admin/workers/detail/_id@string'
import { Methods as Methods26 } from './admin/workers/frozen'
import { Methods as Methods27 } from './admin/workers/leave'
import { Methods as Methods28 } from './admin/workers/qualification'
import { Methods as Methods29 } from './admin/workers/qualification/_id@string'
import { Methods as Methods30 } from './admin/workers/rejection'
import { Methods as Methods31 } from './admin/workers/transfer_request'
import { Methods as Methods32 } from './admin/workers/transfer_request/_id@number'
import { Methods as Methods33 } from './admin/workers/transfer_request/_id@number/approve'
import { Methods as Methods34 } from './admin/workers/transfer_request/_id@number/change_to_error'
import { Methods as Methods35 } from './admin/workers/transfer_request/_id@number/resolve_error'
import { Methods as Methods36 } from './admin/workers/transfer_request/_id@number/transfer_manually'
import { Methods as Methods37 } from './admin/workers/transfer_request/bulk_update_admin_processed'
import { Methods as Methods38 } from './admin/workers/verify'
import { Methods as Methods39 } from './auth/login'
import { Methods as Methods40 } from './auth/password/change'
import { Methods as Methods41 } from './auth/password/reset'
import { Methods as Methods42 } from './auth/password/reset/confirm'
import { Methods as Methods43 } from './auth/registration'
import { Methods as Methods44 } from './auth/registration/other_users'
import { Methods as Methods45 } from './auth/registration/validate_email'
import { Methods as Methods46 } from './auth/registration/verify-email'
import { Methods as Methods47 } from './auth/token/refresh'
import { Methods as Methods48 } from './auth/token/verify'
import { Methods as Methods49 } from './balances'
import { Methods as Methods50 } from './balances/history'
import { Methods as Methods51 } from './balances/request_withdraw'
import { Methods as Methods52 } from './corporations'
import { Methods as Methods53 } from './corporations/_id@number'
import { Methods as Methods54 } from './corporations/accounts/requests'
import { Methods as Methods55 } from './corporations/accounts/requests/_id@number'
import { Methods as Methods56 } from './corporations/accounts/requests/_id@number/accept'
import { Methods as Methods57 } from './corporations/accounts/requests/_id@number/reject'
import { Methods as Methods58 } from './corporations/offices'
import { Methods as Methods59 } from './corporations/offices/_id@number'
import { Methods as Methods60 } from './corporations/sales_responsibilities'
import { Methods as Methods61 } from './corporations/workers'
import { Methods as Methods62 } from './corporations/workers/_id@number'
import { Methods as Methods63 } from './localities'
import { Methods as Methods64 } from './localities/_id@number'
import { Methods as Methods65 } from './notifications'
import { Methods as Methods66 } from './notifications/_id@number'
import { Methods as Methods67 } from './notifications/_id@number/read_notification'
import { Methods as Methods68 } from './orders'
import { Methods as Methods69 } from './orders/_id@number'
import { Methods as Methods70 } from './orders/_id@number/avg_evaluations'
import { Methods as Methods71 } from './orders/admin_applications/_id@number'
import { Methods as Methods72 } from './orders/admin_applications/_id@number/reset_work'
import { Methods as Methods73 } from './orders/application_messages'
import { Methods as Methods74 } from './orders/applications'
import { Methods as Methods75 } from './orders/applications/_id@number'
import { Methods as Methods76 } from './orders/applications/_id@number/cancel'
import { Methods as Methods77 } from './orders/applications/_id@number/finish_work'
import { Methods as Methods78 } from './orders/applications/_id@number/start_work'
import { Methods as Methods79 } from './orders/applications/can_apply'
import { Methods as Methods80 } from './orders/attendance_corrections/_id@number'
import { Methods as Methods81 } from './orders/attendance_corrections/_id@number/approve'
import { Methods as Methods82 } from './orders/attendance_corrections/_id@number/reject'
import { Methods as Methods83 } from './orders/cancel_confirmed'
import { Methods as Methods84 } from './orders/cancel_request'
import { Methods as Methods85 } from './orders/cancel_request/_id@number'
import { Methods as Methods86 } from './orders/descriptions'
import { Methods as Methods87 } from './orders/evaluation_by_office'
import { Methods as Methods88 } from './orders/evaluation_by_worker'
import { Methods as Methods89 } from './orders/insufficient_attendances/finish'
import { Methods as Methods90 } from './orders/insufficient_attendances/start'
import { Methods as Methods91 } from './orders/office_to_worker_application_messages'
import { Methods as Methods92 } from './orders/operations'
import { Methods as Methods93 } from './orders/preview_notice_of_employment_url'
import { Methods as Methods94 } from './orders/templates'
import { Methods as Methods95 } from './orders/templates/_id@number'
import { Methods as Methods96 } from './orders/templates/batch_update_attachment_file'
import { Methods as Methods97 } from './orders/templates/download_csv_by_corporation_id'
import { Methods as Methods98 } from './orders/templates/download_csv_by_office_id'
import { Methods as Methods99 } from './orders/templates/summerize_csv'
import { Methods as Methods100 } from './orders/templates/upload_csv'
import { Methods as Methods101 } from './orders/total_avg_evaluations_by_office'
import { Methods as Methods102 } from './orders/total_avg_evaluations_by_worker'
import { Methods as Methods103 } from './orders/total_avg_evaluations_by_worker_list'
import { Methods as Methods104 } from './point_gifts'
import { Methods as Methods105 } from './qualifications'
import { Methods as Methods106 } from './qualifications/rejection_reason'
import { Methods as Methods107 } from './users'
import { Methods as Methods108 } from './users/_id@string'
import { Methods as Methods109 } from './workers'
import { Methods as Methods110 } from './workers/_id@string'
import { Methods as Methods111 } from './workers/_id@string/credit_score'
import { Methods as Methods112 } from './workers/user_experience'
import { Methods as Methods113 } from './workers/work_count/_id@string'

const GET = 'GET'
const POST = 'POST'
const DELETE = 'DELETE'
const PATCH = 'PATCH'
const PATH0 = '/accounts/email/'
const PATH1 = '/sent_confirmation/'
const PATH2 = '/accounts/leave/'
const PATH3 = '/accounts/me/'
const PATH4 = '/accounts/me/bank_account/'
const PATH5 = '/accounts/me/is_staff/'
const PATH6 = '/accounts/me/is_superuser/'
const PATH7 = '/accounts/me/qualifications/'
const PATH8 = '/'
const PATH9 = '/accounts/me/request_verification/'
const PATH10 = '/accounts/rejection_reason/'
const PATH11 = '/accounts/verification_request_queue/'
const PATH12 = '/check_duplicate/'
const PATH13 = '/admin/adminlog/'
const PATH14 = '/admin/auth/login/'
const PATH15 = '/admin/gift_request/'
const PATH16 = '/admin/information/'
const PATH17 = '/download_target_list_csv/'
const PATH18 = '/admin/workers/'
const PATH19 = '/update_memo/'
const PATH20 = '/admin/workers/bank_account/'
const PATH21 = '/admin/workers/bank_account/detail/'
const PATH22 = '/admin/workers/detail/'
const PATH23 = '/admin/workers/frozen/'
const PATH24 = '/admin/workers/leave/'
const PATH25 = '/admin/workers/qualification/'
const PATH26 = '/admin/workers/rejection/'
const PATH27 = '/admin/workers/transfer_request/'
const PATH28 = '/approve/'
const PATH29 = '/change_to_error/'
const PATH30 = '/resolve_error/'
const PATH31 = '/transfer_manually/'
const PATH32 = '/admin/workers/transfer_request/bulk_update_admin_processed/'
const PATH33 = '/admin/workers/verify/'
const PATH34 = '/auth/login/'
const PATH35 = '/auth/password/change/'
const PATH36 = '/auth/password/reset/'
const PATH37 = '/auth/password/reset/confirm/'
const PATH38 = '/auth/registration/'
const PATH39 = '/auth/registration/other_users/'
const PATH40 = '/auth/registration/validate_email/'
const PATH41 = '/auth/registration/verify-email/'
const PATH42 = '/auth/token/refresh/'
const PATH43 = '/auth/token/verify/'
const PATH44 = '/balances/'
const PATH45 = '/balances/history/'
const PATH46 = '/balances/request_withdraw/'
const PATH47 = '/corporations/'
const PATH48 = '/corporations/accounts/requests/'
const PATH49 = '/accept/'
const PATH50 = '/reject/'
const PATH51 = '/corporations/offices/'
const PATH52 = '/corporations/sales_responsibilities/'
const PATH53 = '/corporations/workers/'
const PATH54 = '/localities/'
const PATH55 = '/notifications/'
const PATH56 = '/read_notification/'
const PATH57 = '/orders/'
const PATH58 = '/avg_evaluations/'
const PATH59 = '/orders/admin_applications/'
const PATH60 = '/reset_work/'
const PATH61 = '/orders/application_messages/'
const PATH62 = '/orders/applications/'
const PATH63 = '/cancel/'
const PATH64 = '/finish_work/'
const PATH65 = '/start_work/'
const PATH66 = '/orders/applications/can_apply/'
const PATH67 = '/orders/attendance_corrections/'
const PATH68 = '/orders/cancel_confirmed/'
const PATH69 = '/orders/cancel_request/'
const PATH70 = '/orders/descriptions/'
const PATH71 = '/orders/evaluation_by_office/'
const PATH72 = '/orders/evaluation_by_worker/'
const PATH73 = '/orders/insufficient_attendances/finish/'
const PATH74 = '/orders/insufficient_attendances/start/'
const PATH75 = '/orders/office_to_worker_application_messages/'
const PATH76 = '/orders/operations/'
const PATH77 = '/orders/preview_notice_of_employment_url/'
const PATH78 = '/orders/templates/'
const PATH79 = '/orders/templates/batch_update_attachment_file/'
const PATH80 = '/orders/templates/download_csv_by_corporation_id/'
const PATH81 = '/orders/templates/download_csv_by_office_id/'
const PATH82 = '/orders/templates/summerize_csv/'
const PATH83 = '/orders/templates/upload_csv/'
const PATH84 = '/orders/total_avg_evaluations_by_office/'
const PATH85 = '/orders/total_avg_evaluations_by_worker/'
const PATH86 = '/orders/total_avg_evaluations_by_worker_list/'
const PATH87 = '/point_gifts/'
const PATH88 = '/qualifications/'
const PATH89 = '/qualifications/rejection_reason/'
const PATH90 = '/users/'
const PATH91 = '/workers/'
const PATH92 = '/credit_score/'
const PATH93 = '/workers/user_experience/'
const PATH94 = '/workers/work_count/'
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')

  return {
    accounts: {
      email: {
        _id: (val0: number) => {
          const prefix0 = `${PATH0}${val0}`

          return {
            sent_confirmation: {
              post: (option?: { config?: T }) =>
                fetch<Methods1['post']['resBody']>(prefix, `${prefix0}${PATH1}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods1['post']['resBody']>(prefix, `${prefix0}${PATH1}`, POST, option).json().then(r => r.body)
            }
          }
        },
        get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
        $get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
        post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
          fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json(),
        $post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
          fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body)
      },
      leave: {
        post: (option?: { config?: T }) =>
          fetch<Methods2['post']['resBody']>(prefix, PATH2, POST, option).json(),
        $post: (option?: { config?: T }) =>
          fetch<Methods2['post']['resBody']>(prefix, PATH2, POST, option).json().then(r => r.body)
      },
      me: {
        bank_account: {
          get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody']>(prefix, PATH4, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody']>(prefix, PATH4, GET, option).json().then(r => r.body),
          post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
            fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json(),
          $post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
            fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body),
          patch: (option: { body: Methods4['patch']['reqBody'], config?: T }) =>
            fetch<Methods4['patch']['resBody']>(prefix, PATH4, PATCH, option).json(),
          $patch: (option: { body: Methods4['patch']['reqBody'], config?: T }) =>
            fetch<Methods4['patch']['resBody']>(prefix, PATH4, PATCH, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH4, DELETE, option).send(),
          $delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH4, DELETE, option).send().then(r => r.body)
        },
        is_staff: {
          get: (option?: { config?: T }) =>
            fetch<Methods5['get']['resBody']>(prefix, PATH5, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods5['get']['resBody']>(prefix, PATH5, GET, option).json().then(r => r.body)
        },
        is_superuser: {
          get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body)
        },
        qualifications: {
          _id: (val1: number) => {
            const prefix1 = `${PATH7}${val1}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods8['get']['resBody']>(prefix, `${prefix1}${PATH8}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods8['get']['resBody']>(prefix, `${prefix1}${PATH8}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods8['patch']['reqBody'], config?: T }) =>
                fetch<Methods8['patch']['resBody']>(prefix, `${prefix1}${PATH8}`, PATCH, option).json(),
              $patch: (option: { body: Methods8['patch']['reqBody'], config?: T }) =>
                fetch<Methods8['patch']['resBody']>(prefix, `${prefix1}${PATH8}`, PATCH, option).json().then(r => r.body),
              delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix1}${PATH8}`, DELETE, option).send(),
              $delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix1}${PATH8}`, DELETE, option).send().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json(),
          $get: (option?: { query?: Methods7['get']['query'], config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json().then(r => r.body),
          post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody']>(prefix, PATH7, POST, option).json(),
          $post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody']>(prefix, PATH7, POST, option).json().then(r => r.body)
        },
        request_verification: {
          post: (option?: { config?: T }) =>
            fetch<Methods9['post']['resBody']>(prefix, PATH9, POST, option).json(),
          $post: (option?: { config?: T }) =>
            fetch<Methods9['post']['resBody']>(prefix, PATH9, POST, option).json().then(r => r.body)
        },
        get: (option?: { config?: T }) =>
          fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json().then(r => r.body),
        patch: (option: { body: Methods3['patch']['reqBody'], config?: T }) =>
          fetch<Methods3['patch']['resBody']>(prefix, PATH3, PATCH, option).json(),
        $patch: (option: { body: Methods3['patch']['reqBody'], config?: T }) =>
          fetch<Methods3['patch']['resBody']>(prefix, PATH3, PATCH, option).json().then(r => r.body)
      },
      rejection_reason: {
        get: (option?: { query?: Methods10['get']['query'], config?: T }) =>
          fetch<Methods10['get']['resBody']>(prefix, PATH10, GET, option).json(),
        $get: (option?: { query?: Methods10['get']['query'], config?: T }) =>
          fetch<Methods10['get']['resBody']>(prefix, PATH10, GET, option).json().then(r => r.body)
      },
      verification_request_queue: {
        _id: (val2: number) => {
          const prefix2 = `${PATH11}${val2}`

          return {
            check_duplicate: {
              post: (option?: { config?: T }) =>
                fetch<Methods13['post']['resBody']>(prefix, `${prefix2}${PATH12}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods13['post']['resBody']>(prefix, `${prefix2}${PATH12}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods12['get']['resBody']>(prefix, `${prefix2}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods12['get']['resBody']>(prefix, `${prefix2}${PATH8}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods12['patch']['reqBody'], config?: T }) =>
              fetch<Methods12['patch']['resBody']>(prefix, `${prefix2}${PATH8}`, PATCH, option).json(),
            $patch: (option: { body: Methods12['patch']['reqBody'], config?: T }) =>
              fetch<Methods12['patch']['resBody']>(prefix, `${prefix2}${PATH8}`, PATCH, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods11['get']['query'], config?: T }) =>
          fetch<Methods11['get']['resBody']>(prefix, PATH11, GET, option).json(),
        $get: (option?: { query?: Methods11['get']['query'], config?: T }) =>
          fetch<Methods11['get']['resBody']>(prefix, PATH11, GET, option).json().then(r => r.body)
      }
    },
    admin: {
      adminlog: {
        get: (option?: { query?: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody']>(prefix, PATH13, GET, option).json(),
        $get: (option?: { query?: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody']>(prefix, PATH13, GET, option).json().then(r => r.body)
      },
      auth: {
        login: {
          post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
            fetch<Methods15['post']['resBody']>(prefix, PATH14, POST, option).json(),
          $post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
            fetch<Methods15['post']['resBody']>(prefix, PATH14, POST, option).json().then(r => r.body)
        }
      },
      gift_request: {
        _id: (val3: number) => {
          const prefix3 = `${PATH15}${val3}`

          return {
            patch: (option: { body: Methods17['patch']['reqBody'], config?: T }) =>
              fetch<Methods17['patch']['resBody']>(prefix, `${prefix3}${PATH8}`, PATCH, option).json(),
            $patch: (option: { body: Methods17['patch']['reqBody'], config?: T }) =>
              fetch<Methods17['patch']['resBody']>(prefix, `${prefix3}${PATH8}`, PATCH, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods16['get']['query'], config?: T }) =>
          fetch<Methods16['get']['resBody']>(prefix, PATH15, GET, option).json(),
        $get: (option?: { query?: Methods16['get']['query'], config?: T }) =>
          fetch<Methods16['get']['resBody']>(prefix, PATH15, GET, option).json().then(r => r.body)
      },
      information: {
        _id: (val4: number) => {
          const prefix4 = `${PATH16}${val4}`

          return {
            download_target_list_csv: {
              get: (option?: { config?: T }) =>
                fetch<Methods20['get']['resBody']>(prefix, `${prefix4}${PATH17}`, GET, option).blob(),
              $get: (option?: { config?: T }) =>
                fetch<Methods20['get']['resBody']>(prefix, `${prefix4}${PATH17}`, GET, option).blob().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods19['get']['resBody']>(prefix, `${prefix4}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods19['get']['resBody']>(prefix, `${prefix4}${PATH8}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods19['patch']['reqBody'], config?: T }) =>
              fetch<Methods19['patch']['resBody']>(prefix, `${prefix4}${PATH8}`, PATCH, option).json(),
            $patch: (option: { body: Methods19['patch']['reqBody'], config?: T }) =>
              fetch<Methods19['patch']['resBody']>(prefix, `${prefix4}${PATH8}`, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix4}${PATH8}`, DELETE, option).send(),
            $delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix4}${PATH8}`, DELETE, option).send().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods18['get']['query'], config?: T }) =>
          fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json(),
        $get: (option?: { query?: Methods18['get']['query'], config?: T }) =>
          fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body),
        post: (option: { body: Methods18['post']['reqBody'], config?: T }) =>
          fetch<Methods18['post']['resBody']>(prefix, PATH16, POST, option, 'FormData').json(),
        $post: (option: { body: Methods18['post']['reqBody'], config?: T }) =>
          fetch<Methods18['post']['resBody']>(prefix, PATH16, POST, option, 'FormData').json().then(r => r.body)
      },
      workers: {
        _id: (val5: string) => {
          const prefix5 = `${PATH18}${val5}`

          return {
            update_memo: {
              patch: (option: { body: Methods22['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix5}${PATH19}`, PATCH, option).send(),
              $patch: (option: { body: Methods22['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix5}${PATH19}`, PATCH, option).send().then(r => r.body)
            }
          }
        },
        bank_account: {
          detail: {
            _id: (val6: number) => {
              const prefix6 = `${PATH21}${val6}`

              return {
                get: (option?: { config?: T }) =>
                  fetch<Methods24['get']['resBody']>(prefix, `${prefix6}${PATH8}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods24['get']['resBody']>(prefix, `${prefix6}${PATH8}`, GET, option).json().then(r => r.body),
                patch: (option: { body: Methods24['patch']['reqBody'], config?: T }) =>
                  fetch<Methods24['patch']['resBody']>(prefix, `${prefix6}${PATH8}`, PATCH, option).json(),
                $patch: (option: { body: Methods24['patch']['reqBody'], config?: T }) =>
                  fetch<Methods24['patch']['resBody']>(prefix, `${prefix6}${PATH8}`, PATCH, option).json().then(r => r.body)
              }
            }
          },
          get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
            fetch<Methods23['get']['resBody']>(prefix, PATH20, GET, option).json(),
          $get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
            fetch<Methods23['get']['resBody']>(prefix, PATH20, GET, option).json().then(r => r.body)
        },
        detail: {
          _id: (val7: string) => {
            const prefix7 = `${PATH22}${val7}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods25['get']['resBody']>(prefix, `${prefix7}${PATH8}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods25['get']['resBody']>(prefix, `${prefix7}${PATH8}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods25['patch']['reqBody'], config?: T }) =>
                fetch<Methods25['patch']['resBody']>(prefix, `${prefix7}${PATH8}`, PATCH, option).json(),
              $patch: (option: { body: Methods25['patch']['reqBody'], config?: T }) =>
                fetch<Methods25['patch']['resBody']>(prefix, `${prefix7}${PATH8}`, PATCH, option).json().then(r => r.body)
            }
          }
        },
        frozen: {
          post: (option: { body: Methods26['post']['reqBody'], config?: T }) =>
            fetch<Methods26['post']['resBody']>(prefix, PATH23, POST, option).json(),
          $post: (option: { body: Methods26['post']['reqBody'], config?: T }) =>
            fetch<Methods26['post']['resBody']>(prefix, PATH23, POST, option).json().then(r => r.body)
        },
        leave: {
          post: (option: { body: Methods27['post']['reqBody'], config?: T }) =>
            fetch<Methods27['post']['resBody']>(prefix, PATH24, POST, option).json(),
          $post: (option: { body: Methods27['post']['reqBody'], config?: T }) =>
            fetch<Methods27['post']['resBody']>(prefix, PATH24, POST, option).json().then(r => r.body)
        },
        qualification: {
          _id: (val8: string) => {
            const prefix8 = `${PATH25}${val8}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods29['get']['resBody']>(prefix, `${prefix8}${PATH8}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods29['get']['resBody']>(prefix, `${prefix8}${PATH8}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods29['patch']['reqBody'], config?: T }) =>
                fetch<Methods29['patch']['resBody']>(prefix, `${prefix8}${PATH8}`, PATCH, option).json(),
              $patch: (option: { body: Methods29['patch']['reqBody'], config?: T }) =>
                fetch<Methods29['patch']['resBody']>(prefix, `${prefix8}${PATH8}`, PATCH, option).json().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH25, GET, option).json(),
          $get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH25, GET, option).json().then(r => r.body)
        },
        rejection: {
          post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
            fetch<Methods30['post']['resBody']>(prefix, PATH26, POST, option).json(),
          $post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
            fetch<Methods30['post']['resBody']>(prefix, PATH26, POST, option).json().then(r => r.body)
        },
        transfer_request: {
          _id: (val9: number) => {
            const prefix9 = `${PATH27}${val9}`

            return {
              approve: {
                post: (option?: { config?: T }) =>
                  fetch<Methods33['post']['resBody']>(prefix, `${prefix9}${PATH28}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods33['post']['resBody']>(prefix, `${prefix9}${PATH28}`, POST, option).text().then(r => r.body)
              },
              change_to_error: {
                post: (option?: { config?: T }) =>
                  fetch<Methods34['post']['resBody']>(prefix, `${prefix9}${PATH29}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods34['post']['resBody']>(prefix, `${prefix9}${PATH29}`, POST, option).text().then(r => r.body)
              },
              resolve_error: {
                post: (option?: { config?: T }) =>
                  fetch<Methods35['post']['resBody']>(prefix, `${prefix9}${PATH30}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods35['post']['resBody']>(prefix, `${prefix9}${PATH30}`, POST, option).text().then(r => r.body)
              },
              transfer_manually: {
                post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
                  fetch<Methods36['post']['resBody']>(prefix, `${prefix9}${PATH31}`, POST, option).text(),
                $post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
                  fetch<Methods36['post']['resBody']>(prefix, `${prefix9}${PATH31}`, POST, option).text().then(r => r.body)
              },
              patch: (option: { body: Methods32['patch']['reqBody'], config?: T }) =>
                fetch<Methods32['patch']['resBody']>(prefix, `${prefix9}${PATH8}`, PATCH, option).json(),
              $patch: (option: { body: Methods32['patch']['reqBody'], config?: T }) =>
                fetch<Methods32['patch']['resBody']>(prefix, `${prefix9}${PATH8}`, PATCH, option).json().then(r => r.body)
            }
          },
          bulk_update_admin_processed: {
            post: (option: { body: Methods37['post']['reqBody'], config?: T }) =>
              fetch<Methods37['post']['resBody']>(prefix, PATH32, POST, option).json(),
            $post: (option: { body: Methods37['post']['reqBody'], config?: T }) =>
              fetch<Methods37['post']['resBody']>(prefix, PATH32, POST, option).json().then(r => r.body)
          },
          get: (option?: { query?: Methods31['get']['query'], config?: T }) =>
            fetch<Methods31['get']['resBody']>(prefix, PATH27, GET, option).json(),
          $get: (option?: { query?: Methods31['get']['query'], config?: T }) =>
            fetch<Methods31['get']['resBody']>(prefix, PATH27, GET, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
            fetch<Methods38['post']['resBody']>(prefix, PATH33, POST, option).json(),
          $post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
            fetch<Methods38['post']['resBody']>(prefix, PATH33, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods21['get']['query'], config?: T }) =>
          fetch<Methods21['get']['resBody']>(prefix, PATH18, GET, option).json(),
        $get: (option?: { query?: Methods21['get']['query'], config?: T }) =>
          fetch<Methods21['get']['resBody']>(prefix, PATH18, GET, option).json().then(r => r.body)
      }
    },
    auth: {
      login: {
        post: (option: { body: Methods39['post']['reqBody'], config?: T }) =>
          fetch<Methods39['post']['resBody']>(prefix, PATH34, POST, option).json(),
        $post: (option: { body: Methods39['post']['reqBody'], config?: T }) =>
          fetch<Methods39['post']['resBody']>(prefix, PATH34, POST, option).json().then(r => r.body)
      },
      password: {
        change: {
          post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
            fetch<Methods40['post']['resBody']>(prefix, PATH35, POST, option).json(),
          $post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
            fetch<Methods40['post']['resBody']>(prefix, PATH35, POST, option).json().then(r => r.body)
        },
        reset: {
          confirm: {
            post: (option: { body: Methods42['post']['reqBody'], config?: T }) =>
              fetch<Methods42['post']['resBody']>(prefix, PATH37, POST, option).json(),
            $post: (option: { body: Methods42['post']['reqBody'], config?: T }) =>
              fetch<Methods42['post']['resBody']>(prefix, PATH37, POST, option).json().then(r => r.body)
          },
          post: (option: { body: Methods41['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH36, POST, option).send(),
          $post: (option: { body: Methods41['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH36, POST, option).send().then(r => r.body)
        }
      },
      registration: {
        other_users: {
          post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
            fetch<Methods44['post']['resBody']>(prefix, PATH39, POST, option).json(),
          $post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
            fetch<Methods44['post']['resBody']>(prefix, PATH39, POST, option).json().then(r => r.body)
        },
        validate_email: {
          post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH40, POST, option).json(),
          $post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH40, POST, option).json().then(r => r.body)
        },
        verify_email: {
          post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
            fetch<Methods46['post']['resBody']>(prefix, PATH41, POST, option).json(),
          $post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
            fetch<Methods46['post']['resBody']>(prefix, PATH41, POST, option).json().then(r => r.body)
        },
        post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
          fetch<Methods43['post']['resBody']>(prefix, PATH38, POST, option).json(),
        $post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
          fetch<Methods43['post']['resBody']>(prefix, PATH38, POST, option).json().then(r => r.body)
      },
      token: {
        refresh: {
          post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
            fetch<Methods47['post']['resBody']>(prefix, PATH42, POST, option).json(),
          $post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
            fetch<Methods47['post']['resBody']>(prefix, PATH42, POST, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
            fetch<Methods48['post']['resBody']>(prefix, PATH43, POST, option).json(),
          $post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
            fetch<Methods48['post']['resBody']>(prefix, PATH43, POST, option).json().then(r => r.body)
        }
      }
    },
    balances: {
      history: {
        get: (option?: { query?: Methods50['get']['query'], config?: T }) =>
          fetch<Methods50['get']['resBody']>(prefix, PATH45, GET, option).json(),
        $get: (option?: { query?: Methods50['get']['query'], config?: T }) =>
          fetch<Methods50['get']['resBody']>(prefix, PATH45, GET, option).json().then(r => r.body)
      },
      request_withdraw: {
        post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
          fetch<Methods51['post']['resBody']>(prefix, PATH46, POST, option).json(),
        $post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
          fetch<Methods51['post']['resBody']>(prefix, PATH46, POST, option).json().then(r => r.body)
      },
      get: (option?: { config?: T }) =>
        fetch<Methods49['get']['resBody']>(prefix, PATH44, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods49['get']['resBody']>(prefix, PATH44, GET, option).json().then(r => r.body)
    },
    corporations: {
      _id: (val10: number) => {
        const prefix10 = `${PATH47}${val10}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods53['get']['resBody']>(prefix, `${prefix10}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods53['get']['resBody']>(prefix, `${prefix10}${PATH8}`, GET, option).json().then(r => r.body),
          post: (option: { body: Methods53['post']['reqBody'], config?: T }) =>
            fetch<Methods53['post']['resBody']>(prefix, `${prefix10}${PATH8}`, POST, option).json(),
          $post: (option: { body: Methods53['post']['reqBody'], config?: T }) =>
            fetch<Methods53['post']['resBody']>(prefix, `${prefix10}${PATH8}`, POST, option).json().then(r => r.body)
        }
      },
      accounts: {
        requests: {
          _id: (val11: number) => {
            const prefix11 = `${PATH48}${val11}`

            return {
              accept: {
                post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
                  fetch<Methods56['post']['resBody']>(prefix, `${prefix11}${PATH49}`, POST, option).json(),
                $post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
                  fetch<Methods56['post']['resBody']>(prefix, `${prefix11}${PATH49}`, POST, option).json().then(r => r.body)
              },
              reject: {
                post: (option: { body: Methods57['post']['reqBody'], config?: T }) =>
                  fetch<Methods57['post']['resBody']>(prefix, `${prefix11}${PATH50}`, POST, option).json(),
                $post: (option: { body: Methods57['post']['reqBody'], config?: T }) =>
                  fetch<Methods57['post']['resBody']>(prefix, `${prefix11}${PATH50}`, POST, option).json().then(r => r.body)
              },
              get: (option?: { config?: T }) =>
                fetch<Methods55['get']['resBody']>(prefix, `${prefix11}${PATH8}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods55['get']['resBody']>(prefix, `${prefix11}${PATH8}`, GET, option).json().then(r => r.body),
              post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                fetch<Methods55['post']['resBody']>(prefix, `${prefix11}${PATH8}`, POST, option).json(),
              $post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                fetch<Methods55['post']['resBody']>(prefix, `${prefix11}${PATH8}`, POST, option).json().then(r => r.body),
              patch: (option: { body: Methods55['patch']['reqBody'], config?: T }) =>
                fetch<Methods55['patch']['resBody']>(prefix, `${prefix11}${PATH8}`, PATCH, option).json(),
              $patch: (option: { body: Methods55['patch']['reqBody'], config?: T }) =>
                fetch<Methods55['patch']['resBody']>(prefix, `${prefix11}${PATH8}`, PATCH, option).json().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods54['get']['query'], config?: T }) =>
            fetch<Methods54['get']['resBody']>(prefix, PATH48, GET, option).json(),
          $get: (option?: { query?: Methods54['get']['query'], config?: T }) =>
            fetch<Methods54['get']['resBody']>(prefix, PATH48, GET, option).json().then(r => r.body),
          post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
            fetch<Methods54['post']['resBody']>(prefix, PATH48, POST, option).json(),
          $post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
            fetch<Methods54['post']['resBody']>(prefix, PATH48, POST, option).json().then(r => r.body)
        }
      },
      offices: {
        _id: (val12: number) => {
          const prefix12 = `${PATH51}${val12}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods59['get']['resBody']>(prefix, `${prefix12}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods59['get']['resBody']>(prefix, `${prefix12}${PATH8}`, GET, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods58['get']['query'], config?: T }) =>
          fetch<Methods58['get']['resBody']>(prefix, PATH51, GET, option).json(),
        $get: (option?: { query?: Methods58['get']['query'], config?: T }) =>
          fetch<Methods58['get']['resBody']>(prefix, PATH51, GET, option).json().then(r => r.body)
      },
      sales_responsibilities: {
        get: (option?: { config?: T }) =>
          fetch<Methods60['get']['resBody']>(prefix, PATH52, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods60['get']['resBody']>(prefix, PATH52, GET, option).json().then(r => r.body)
      },
      workers: {
        _id: (val13: number) => {
          const prefix13 = `${PATH53}${val13}`

          return {
            patch: (option: { body: Methods62['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix13}${PATH8}`, PATCH, option).send(),
            $patch: (option: { body: Methods62['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix13}${PATH8}`, PATCH, option).send().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods61['get']['query'], config?: T }) =>
          fetch<Methods61['get']['resBody']>(prefix, PATH53, GET, option).json(),
        $get: (option?: { query?: Methods61['get']['query'], config?: T }) =>
          fetch<Methods61['get']['resBody']>(prefix, PATH53, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods52['get']['query'], config?: T }) =>
        fetch<Methods52['get']['resBody']>(prefix, PATH47, GET, option).json(),
      $get: (option?: { query?: Methods52['get']['query'], config?: T }) =>
        fetch<Methods52['get']['resBody']>(prefix, PATH47, GET, option).json().then(r => r.body),
      post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
        fetch<Methods52['post']['resBody']>(prefix, PATH47, POST, option).json(),
      $post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
        fetch<Methods52['post']['resBody']>(prefix, PATH47, POST, option).json().then(r => r.body)
    },
    localities: {
      _id: (val14: number) => {
        const prefix14 = `${PATH54}${val14}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods64['get']['resBody']>(prefix, `${prefix14}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods64['get']['resBody']>(prefix, `${prefix14}${PATH8}`, GET, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods63['get']['query'], config?: T }) =>
        fetch<Methods63['get']['resBody']>(prefix, PATH54, GET, option).json(),
      $get: (option?: { query?: Methods63['get']['query'], config?: T }) =>
        fetch<Methods63['get']['resBody']>(prefix, PATH54, GET, option).json().then(r => r.body)
    },
    notifications: {
      _id: (val15: number) => {
        const prefix15 = `${PATH55}${val15}`

        return {
          read_notification: {
            patch: (option?: { config?: T }) =>
              fetch<Methods67['patch']['resBody']>(prefix, `${prefix15}${PATH56}`, PATCH, option).json(),
            $patch: (option?: { config?: T }) =>
              fetch<Methods67['patch']['resBody']>(prefix, `${prefix15}${PATH56}`, PATCH, option).json().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods66['get']['resBody']>(prefix, `${prefix15}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods66['get']['resBody']>(prefix, `${prefix15}${PATH8}`, GET, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods65['get']['query'], config?: T }) =>
        fetch<Methods65['get']['resBody']>(prefix, PATH55, GET, option).json(),
      $get: (option?: { query?: Methods65['get']['query'], config?: T }) =>
        fetch<Methods65['get']['resBody']>(prefix, PATH55, GET, option).json().then(r => r.body)
    },
    orders: {
      _id: (val16: number) => {
        const prefix16 = `${PATH57}${val16}`

        return {
          avg_evaluations: {
            get: (option?: { config?: T }) =>
              fetch<Methods70['get']['resBody']>(prefix, `${prefix16}${PATH58}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods70['get']['resBody']>(prefix, `${prefix16}${PATH58}`, GET, option).json().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods69['get']['resBody']>(prefix, `${prefix16}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods69['get']['resBody']>(prefix, `${prefix16}${PATH8}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods69['patch']['reqBody'], config?: T }) =>
            fetch<Methods69['patch']['resBody']>(prefix, `${prefix16}${PATH8}`, PATCH, option).json(),
          $patch: (option: { body: Methods69['patch']['reqBody'], config?: T }) =>
            fetch<Methods69['patch']['resBody']>(prefix, `${prefix16}${PATH8}`, PATCH, option).json().then(r => r.body)
        }
      },
      admin_applications: {
        _id: (val17: number) => {
          const prefix17 = `${PATH59}${val17}`

          return {
            reset_work: {
              post: (option?: { config?: T }) =>
                fetch<Methods72['post']['resBody']>(prefix, `${prefix17}${PATH60}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods72['post']['resBody']>(prefix, `${prefix17}${PATH60}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods71['get']['resBody']>(prefix, `${prefix17}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods71['get']['resBody']>(prefix, `${prefix17}${PATH8}`, GET, option).json().then(r => r.body)
          }
        }
      },
      application_messages: {
        get: (option?: { query?: Methods73['get']['query'], config?: T }) =>
          fetch<Methods73['get']['resBody']>(prefix, PATH61, GET, option).json(),
        $get: (option?: { query?: Methods73['get']['query'], config?: T }) =>
          fetch<Methods73['get']['resBody']>(prefix, PATH61, GET, option).json().then(r => r.body),
        post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
          fetch<Methods73['post']['resBody']>(prefix, PATH61, POST, option).json(),
        $post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
          fetch<Methods73['post']['resBody']>(prefix, PATH61, POST, option).json().then(r => r.body)
      },
      applications: {
        _id: (val18: number) => {
          const prefix18 = `${PATH62}${val18}`

          return {
            cancel: {
              post: (option?: { config?: T }) =>
                fetch<Methods76['post']['resBody']>(prefix, `${prefix18}${PATH63}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods76['post']['resBody']>(prefix, `${prefix18}${PATH63}`, POST, option).json().then(r => r.body)
            },
            finish_work: {
              post: (option: { body: Methods77['post']['reqBody'], config?: T }) =>
                fetch<Methods77['post']['resBody']>(prefix, `${prefix18}${PATH64}`, POST, option).json(),
              $post: (option: { body: Methods77['post']['reqBody'], config?: T }) =>
                fetch<Methods77['post']['resBody']>(prefix, `${prefix18}${PATH64}`, POST, option).json().then(r => r.body)
            },
            start_work: {
              post: (option: { body: Methods78['post']['reqBody'], config?: T }) =>
                fetch<Methods78['post']['resBody']>(prefix, `${prefix18}${PATH65}`, POST, option).json(),
              $post: (option: { body: Methods78['post']['reqBody'], config?: T }) =>
                fetch<Methods78['post']['resBody']>(prefix, `${prefix18}${PATH65}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods75['get']['resBody']>(prefix, `${prefix18}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods75['get']['resBody']>(prefix, `${prefix18}${PATH8}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods75['patch']['reqBody'], config?: T }) =>
              fetch<Methods75['patch']['resBody']>(prefix, `${prefix18}${PATH8}`, PATCH, option).json(),
            $patch: (option: { body: Methods75['patch']['reqBody'], config?: T }) =>
              fetch<Methods75['patch']['resBody']>(prefix, `${prefix18}${PATH8}`, PATCH, option).json().then(r => r.body)
          }
        },
        can_apply: {
          post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
            fetch<Methods79['post']['resBody']>(prefix, PATH66, POST, option).json(),
          $post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
            fetch<Methods79['post']['resBody']>(prefix, PATH66, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods74['get']['query'], config?: T }) =>
          fetch<Methods74['get']['resBody']>(prefix, PATH62, GET, option).json(),
        $get: (option?: { query?: Methods74['get']['query'], config?: T }) =>
          fetch<Methods74['get']['resBody']>(prefix, PATH62, GET, option).json().then(r => r.body),
        post: (option: { body: Methods74['post']['reqBody'], config?: T }) =>
          fetch<Methods74['post']['resBody']>(prefix, PATH62, POST, option).json(),
        $post: (option: { body: Methods74['post']['reqBody'], config?: T }) =>
          fetch<Methods74['post']['resBody']>(prefix, PATH62, POST, option).json().then(r => r.body)
      },
      attendance_corrections: {
        _id: (val19: number) => {
          const prefix19 = `${PATH67}${val19}`

          return {
            approve: {
              post: (option: { body: Methods81['post']['reqBody'], config?: T }) =>
                fetch<Methods81['post']['resBody']>(prefix, `${prefix19}${PATH28}`, POST, option).json(),
              $post: (option: { body: Methods81['post']['reqBody'], config?: T }) =>
                fetch<Methods81['post']['resBody']>(prefix, `${prefix19}${PATH28}`, POST, option).json().then(r => r.body)
            },
            reject: {
              post: (option: { body: Methods82['post']['reqBody'], config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix19}${PATH50}`, POST, option).json(),
              $post: (option: { body: Methods82['post']['reqBody'], config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix19}${PATH50}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods80['get']['resBody']>(prefix, `${prefix19}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods80['get']['resBody']>(prefix, `${prefix19}${PATH8}`, GET, option).json().then(r => r.body)
          }
        }
      },
      cancel_confirmed: {
        post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH68, POST, option).send(),
        $post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH68, POST, option).send().then(r => r.body)
      },
      cancel_request: {
        _id: (val20: number) => {
          const prefix20 = `${PATH69}${val20}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods85['get']['resBody']>(prefix, `${prefix20}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods85['get']['resBody']>(prefix, `${prefix20}${PATH8}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods85['patch']['reqBody'], config?: T }) =>
              fetch<Methods85['patch']['resBody']>(prefix, `${prefix20}${PATH8}`, PATCH, option).json(),
            $patch: (option: { body: Methods85['patch']['reqBody'], config?: T }) =>
              fetch<Methods85['patch']['resBody']>(prefix, `${prefix20}${PATH8}`, PATCH, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
          fetch<Methods84['get']['resBody']>(prefix, PATH69, GET, option).json(),
        $get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
          fetch<Methods84['get']['resBody']>(prefix, PATH69, GET, option).json().then(r => r.body),
        post: (option: { body: Methods84['post']['reqBody'], config?: T }) =>
          fetch<Methods84['post']['resBody']>(prefix, PATH69, POST, option).json(),
        $post: (option: { body: Methods84['post']['reqBody'], config?: T }) =>
          fetch<Methods84['post']['resBody']>(prefix, PATH69, POST, option).json().then(r => r.body)
      },
      descriptions: {
        get: (option?: { query?: Methods86['get']['query'], config?: T }) =>
          fetch<Methods86['get']['resBody']>(prefix, PATH70, GET, option).json(),
        $get: (option?: { query?: Methods86['get']['query'], config?: T }) =>
          fetch<Methods86['get']['resBody']>(prefix, PATH70, GET, option).json().then(r => r.body)
      },
      evaluation_by_office: {
        post: (option: { body: Methods87['post']['reqBody'], config?: T }) =>
          fetch<Methods87['post']['resBody']>(prefix, PATH71, POST, option).json(),
        $post: (option: { body: Methods87['post']['reqBody'], config?: T }) =>
          fetch<Methods87['post']['resBody']>(prefix, PATH71, POST, option).json().then(r => r.body)
      },
      evaluation_by_worker: {
        get: (option?: { query?: Methods88['get']['query'], config?: T }) =>
          fetch<Methods88['get']['resBody']>(prefix, PATH72, GET, option).json(),
        $get: (option?: { query?: Methods88['get']['query'], config?: T }) =>
          fetch<Methods88['get']['resBody']>(prefix, PATH72, GET, option).json().then(r => r.body),
        post: (option: { body: Methods88['post']['reqBody'], config?: T }) =>
          fetch<Methods88['post']['resBody']>(prefix, PATH72, POST, option).json(),
        $post: (option: { body: Methods88['post']['reqBody'], config?: T }) =>
          fetch<Methods88['post']['resBody']>(prefix, PATH72, POST, option).json().then(r => r.body)
      },
      insufficient_attendances: {
        finish: {
          get: (option?: { query?: Methods89['get']['query'], config?: T }) =>
            fetch<Methods89['get']['resBody']>(prefix, PATH73, GET, option).json(),
          $get: (option?: { query?: Methods89['get']['query'], config?: T }) =>
            fetch<Methods89['get']['resBody']>(prefix, PATH73, GET, option).json().then(r => r.body)
        },
        start: {
          get: (option?: { query?: Methods90['get']['query'], config?: T }) =>
            fetch<Methods90['get']['resBody']>(prefix, PATH74, GET, option).json(),
          $get: (option?: { query?: Methods90['get']['query'], config?: T }) =>
            fetch<Methods90['get']['resBody']>(prefix, PATH74, GET, option).json().then(r => r.body)
        }
      },
      office_to_worker_application_messages: {
        get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
          fetch<Methods91['get']['resBody']>(prefix, PATH75, GET, option).json(),
        $get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
          fetch<Methods91['get']['resBody']>(prefix, PATH75, GET, option).json().then(r => r.body),
        post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
          fetch<Methods91['post']['resBody']>(prefix, PATH75, POST, option).json(),
        $post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
          fetch<Methods91['post']['resBody']>(prefix, PATH75, POST, option).json().then(r => r.body)
      },
      operations: {
        get: (option?: { query?: Methods92['get']['query'], config?: T }) =>
          fetch<Methods92['get']['resBody']>(prefix, PATH76, GET, option).json(),
        $get: (option?: { query?: Methods92['get']['query'], config?: T }) =>
          fetch<Methods92['get']['resBody']>(prefix, PATH76, GET, option).json().then(r => r.body)
      },
      preview_notice_of_employment_url: {
        post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
          fetch<Methods93['post']['resBody']>(prefix, PATH77, POST, option).json(),
        $post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
          fetch<Methods93['post']['resBody']>(prefix, PATH77, POST, option).json().then(r => r.body)
      },
      templates: {
        _id: (val21: number) => {
          const prefix21 = `${PATH78}${val21}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods95['get']['resBody']>(prefix, `${prefix21}${PATH8}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods95['get']['resBody']>(prefix, `${prefix21}${PATH8}`, GET, option).json().then(r => r.body)
          }
        },
        batch_update_attachment_file: {
          post: (option: { body: Methods96['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH79, POST, option).send(),
          $post: (option: { body: Methods96['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH79, POST, option).send().then(r => r.body)
        },
        download_csv_by_corporation_id: {
          get: (option: { query: Methods97['get']['query'], config?: T }) =>
            fetch<Methods97['get']['resBody']>(prefix, PATH80, GET, option).blob(),
          $get: (option: { query: Methods97['get']['query'], config?: T }) =>
            fetch<Methods97['get']['resBody']>(prefix, PATH80, GET, option).blob().then(r => r.body)
        },
        download_csv_by_office_id: {
          get: (option: { query: Methods98['get']['query'], config?: T }) =>
            fetch<Methods98['get']['resBody']>(prefix, PATH81, GET, option).blob(),
          $get: (option: { query: Methods98['get']['query'], config?: T }) =>
            fetch<Methods98['get']['resBody']>(prefix, PATH81, GET, option).blob().then(r => r.body)
        },
        summerize_csv: {
          post: (option: { body: Methods99['post']['reqBody'], config?: T }) =>
            fetch<Methods99['post']['resBody']>(prefix, PATH82, POST, option).json(),
          $post: (option: { body: Methods99['post']['reqBody'], config?: T }) =>
            fetch<Methods99['post']['resBody']>(prefix, PATH82, POST, option).json().then(r => r.body)
        },
        upload_csv: {
          post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH83, POST, option).send(),
          $post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH83, POST, option).send().then(r => r.body)
        },
        get: (option?: { query?: Methods94['get']['query'], config?: T }) =>
          fetch<Methods94['get']['resBody']>(prefix, PATH78, GET, option).json(),
        $get: (option?: { query?: Methods94['get']['query'], config?: T }) =>
          fetch<Methods94['get']['resBody']>(prefix, PATH78, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_office: {
        get: (option: { query: Methods101['get']['query'], config?: T }) =>
          fetch<Methods101['get']['resBody']>(prefix, PATH84, GET, option).json(),
        $get: (option: { query: Methods101['get']['query'], config?: T }) =>
          fetch<Methods101['get']['resBody']>(prefix, PATH84, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker: {
        get: (option: { query: Methods102['get']['query'], config?: T }) =>
          fetch<Methods102['get']['resBody']>(prefix, PATH85, GET, option).json(),
        $get: (option: { query: Methods102['get']['query'], config?: T }) =>
          fetch<Methods102['get']['resBody']>(prefix, PATH85, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker_list: {
        get: (option: { query: Methods103['get']['query'], config?: T }) =>
          fetch<Methods103['get']['resBody']>(prefix, PATH86, GET, option).json(),
        $get: (option: { query: Methods103['get']['query'], config?: T }) =>
          fetch<Methods103['get']['resBody']>(prefix, PATH86, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
        fetch<Methods68['get']['resBody']>(prefix, PATH57, GET, option).json(),
      $get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
        fetch<Methods68['get']['resBody']>(prefix, PATH57, GET, option).json().then(r => r.body),
      post: (option: { body: Methods68['post']['reqBody'], config?: T }) =>
        fetch<Methods68['post']['resBody']>(prefix, PATH57, POST, option).json(),
      $post: (option: { body: Methods68['post']['reqBody'], config?: T }) =>
        fetch<Methods68['post']['resBody']>(prefix, PATH57, POST, option).json().then(r => r.body)
    },
    point_gifts: {
      get: (option?: { query?: Methods104['get']['query'], config?: T }) =>
        fetch<Methods104['get']['resBody']>(prefix, PATH87, GET, option).json(),
      $get: (option?: { query?: Methods104['get']['query'], config?: T }) =>
        fetch<Methods104['get']['resBody']>(prefix, PATH87, GET, option).json().then(r => r.body)
    },
    qualifications: {
      rejection_reason: {
        get: (option?: { query?: Methods106['get']['query'], config?: T }) =>
          fetch<Methods106['get']['resBody']>(prefix, PATH89, GET, option).json(),
        $get: (option?: { query?: Methods106['get']['query'], config?: T }) =>
          fetch<Methods106['get']['resBody']>(prefix, PATH89, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods105['get']['query'], config?: T }) =>
        fetch<Methods105['get']['resBody']>(prefix, PATH88, GET, option).json(),
      $get: (option?: { query?: Methods105['get']['query'], config?: T }) =>
        fetch<Methods105['get']['resBody']>(prefix, PATH88, GET, option).json().then(r => r.body)
    },
    users: {
      _id: (val22: string) => {
        const prefix22 = `${PATH90}${val22}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods108['get']['resBody']>(prefix, `${prefix22}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods108['get']['resBody']>(prefix, `${prefix22}${PATH8}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods108['patch']['reqBody'], config?: T }) =>
            fetch<Methods108['patch']['resBody']>(prefix, `${prefix22}${PATH8}`, PATCH, option).json(),
          $patch: (option: { body: Methods108['patch']['reqBody'], config?: T }) =>
            fetch<Methods108['patch']['resBody']>(prefix, `${prefix22}${PATH8}`, PATCH, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods107['get']['query'], config?: T }) =>
        fetch<Methods107['get']['resBody']>(prefix, PATH90, GET, option).json(),
      $get: (option?: { query?: Methods107['get']['query'], config?: T }) =>
        fetch<Methods107['get']['resBody']>(prefix, PATH90, GET, option).json().then(r => r.body)
    },
    workers: {
      _id: (val23: string) => {
        const prefix23 = `${PATH91}${val23}`

        return {
          credit_score: {
            patch: (option: { body: Methods111['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix23}${PATH92}`, PATCH, option).send(),
            $patch: (option: { body: Methods111['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix23}${PATH92}`, PATCH, option).send().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods110['get']['resBody']>(prefix, `${prefix23}${PATH8}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods110['get']['resBody']>(prefix, `${prefix23}${PATH8}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods110['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, `${prefix23}${PATH8}`, PATCH, option).send(),
          $patch: (option: { body: Methods110['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, `${prefix23}${PATH8}`, PATCH, option).send().then(r => r.body)
        }
      },
      user_experience: {
        get: (option?: { query?: Methods112['get']['query'], config?: T }) =>
          fetch<Methods112['get']['resBody']>(prefix, PATH93, GET, option).json(),
        $get: (option?: { query?: Methods112['get']['query'], config?: T }) =>
          fetch<Methods112['get']['resBody']>(prefix, PATH93, GET, option).json().then(r => r.body)
      },
      work_count: {
        _id: (val24: string) => {
          const prefix24 = `${PATH94}${val24}`

          return {
            get: (option?: { query?: Methods113['get']['query'], config?: T }) =>
              fetch<Methods113['get']['resBody']>(prefix, `${prefix24}${PATH8}`, GET, option).json(),
            $get: (option?: { query?: Methods113['get']['query'], config?: T }) =>
              fetch<Methods113['get']['resBody']>(prefix, `${prefix24}${PATH8}`, GET, option).json().then(r => r.body)
          }
        }
      },
      get: (option?: { query?: Methods109['get']['query'], config?: T }) =>
        fetch<Methods109['get']['resBody']>(prefix, PATH91, GET, option).json(),
      $get: (option?: { query?: Methods109['get']['query'], config?: T }) =>
        fetch<Methods109['get']['resBody']>(prefix, PATH91, GET, option).json().then(r => r.body)
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
