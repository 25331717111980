import { ValidationStateType } from "@/types/Validation";

/**
 * vee-validate のオブジェクトから bootstrap-vue のコンポーネントに渡す state を取得する
 *
 * @param state
 * @returns
 */
export const getFormState = (state: ValidationStateType) => {
  if (!state || !state.meta) return null;
  return state.meta.validated ? state.meta.valid : null;
};
