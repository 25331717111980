import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import JwtDecode from "jwt-decode";
import { AuthInfo } from "@/types/AuthInfo";
import { User } from "@/types/user/User";

// トークンを破棄するまでの猶予時間（秒）
export const TOKEN_DISCARDING_GRACE_TIME = 5 * 60;

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: "auth",
  preserveState: localStorage.getItem("vuex") !== null
})
class AuthModule extends VuexModule {
  token: string | null = null;
  user: User | null = null;

  get existToken() {
    return !!this.token;
  }

  get tokenObject() {
    if (this.token === null) {
      return null;
    }

    // TODO: 他にもkeyがあるが一旦有効期限だけ型付けしている
    return JwtDecode<{
      exp: number;
    }>(this.token);
  }

  /**
   * token の期限切れまでの秒数取得
   * 関数にしないとDate.now()が走り直さずにキャッシュされてしまう
   */
  get tokenLifetime() {
    const tokenObject = this.tokenObject;
    if (!tokenObject) {
      return () => null;
    }

    // token の exp は Date.now() を数値化した値の秒数なので
    // 今のミリ秒を 1000 で割って算出する
    return () => {
      const current = Date.now().valueOf() / 1000;
      return +tokenObject.exp - current;
    };
  }

  /**
   * 有効期限が切れているかどうか
   * 関数にしないとtokenLifetime()が走り直さずにキャッシュされてしまう
   */
  get tokenIsExpired() {
    return () => {
      const lifetime = this.tokenLifetime();
      return lifetime === null || lifetime <= 0;
    };
  }

  /**
   * 有効期限が指定時間後に切れているかどうか
   * 関数にしないとtokenLifetime()が走り直さずにキャッシュされてしまう
   */
  get tokenIsNearExpired() {
    return () => {
      const lifetime = this.tokenLifetime();
      return lifetime === null || lifetime <= TOKEN_DISCARDING_GRACE_TIME;
    };
  }

  get isSuperUser() {
    return this.user?.isSuperuser;
  }

  @Mutation
  private SET_AUTH_INFO({ token, user }: AuthInfo) {
    this.token = token;
    this.user = user;
  }

  @Mutation
  private CLEAR_AUTH_INFO() {
    this.token = null;
    this.user = null;
  }

  @Mutation
  private SET_NEW_TOKEN({ token }: Omit<AuthInfo, "user">) {
    this.token = token;
  }

  @Mutation
  private SET_USER(user: User) {
    this.user = user;
  }

  @Action
  login(authInfo: AuthInfo) {
    this.SET_AUTH_INFO(authInfo);
  }

  @Action
  logout() {
    this.CLEAR_AUTH_INFO();
  }

  @Action
  refresh(newToken: Omit<AuthInfo, "user">) {
    this.SET_NEW_TOKEN(newToken);
  }

  @Action
  setUserInfo(user: User) {
    this.SET_USER(user);
  }
}

export const authStore = getModule(AuthModule);
