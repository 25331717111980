import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BFormInput = _resolveComponent("BFormInput")!

  return (_openBlock(), _createBlock(_component_BFormInput, {
    value: _ctx.value,
    type: _ctx.type,
    state: _ctx.state,
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    readonly: _ctx.readonly,
    onInput: _ctx.onInput,
    onBlur: _ctx.onBlur,
    class: "app-textfield"
  }, null, 8, ["value", "type", "state", "placeholder", "disabled", "readonly", "onInput", "onBlur"]))
}