import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "vue";
import router from "@/router";

// Initialize Sentry
export function initSentry(app: App<Element>) {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    enabled: process.env.VUE_APP_SENTRY_ENVIRONMENT === "prod",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_SENTRY_TARGET_ORIGIN!, /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracePropagationTargets: [process.env.VUE_APP_API_URL_BASE as string],
    attachStacktrace: true,
    tracesSampleRate: 0.01
  });
}

// Initialize Adobe Marketo Engage Munchkin
// Adobe Marketo Engage is service to tracks user's page transitions.
export function initMarketoMunchkin() {
  if (process.env.VUE_APP_MARKETO_ENABLE_TRACKING?.toLowerCase() !== "true") {
    return;
  }

  // You can get Munchkin ID from My Account page on Marketo Engage management console.
  // Ask biz. team to issue Marketo Engage account.
  const munchkinId = "135-JLT-809";

  let didInit = false;
  function initMunchkin() {
    // @ts-ignore
    const Munchkin = window.Munchkin;
    if (!Munchkin) return;
    if (didInit === false) {
      didInit = true;
      // @ts-ignore
      Munchkin.init(munchkinId, {
        asyncOnly: true,
        apiOnly: true
      });
    }
  }
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "//munchkin.marketo.net/munchkin.js";
  // @ts-ignore
  s.onreadystatechange = function() {
    // @ts-ignore
    if (this.readyState === "complete" || this.readyState === "loaded") {
      initMunchkin();
    }
  };
  s.onload = initMunchkin;
  document.getElementsByTagName("head")[0].appendChild(s);
}
