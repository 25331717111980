import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";

// Actions でエラーが発生した際、元のエラーをラップしない
config.rawError = true;

const persistPlugin = createPersistedState({
  paths: ["auth"]
});

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
  plugins: [persistPlugin]
});
